import React from 'react'
import * as propTypes from 'prop-types'
import { Field, getIn } from 'formik'
import InputHolder from '@components/InputHolder'
import { buildClass } from '@util/tag'
import useTranslation from 'next-translate/useTranslation'

const Textarea = ({
    name,
    label,
    customClass,
    required,
    error,
    inputHolderStyle,
    parentCss,
    customIcon,
    labelClass,
    ...props
}) => {
    const { lang } = useTranslation()
    return (
        <Field name={name}>
            {({ field, form: { errors, touched } }) => {
                return (
                    <InputHolder
                        label={label}
                        error={getIn(touched, field.name) && getIn(errors, field.name)}
                        parentCss={parentCss}
                        labelClass={labelClass}
                        inputHolderStyle={inputHolderStyle}
                    >
                        {customIcon && (
                            <div
                                className={`absolute flex items-center px-4 top-3 ${
                                    lang === 'ar' ? 'right-1' : 'left-1'
                                }`}
                            >
                                {customIcon}
                            </div>
                        )}
                        <textarea
                            id={name}
                            name={field.name}
                            required={required}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            value={field.value}
                            className={buildClass('py-1 px-5 border border-[#e6e7e9] bg-[#fcfcfd]', customClass, {
                                'ps-14': customIcon
                            })}
                            {...props}
                        />
                    </InputHolder>
                )
            }}
        </Field>
    )
}

Textarea.propTypes = {
    name: propTypes.string,
    label: propTypes.oneOfType([propTypes.object, propTypes.string]),
    required: propTypes.bool,
    error: propTypes.string
}
export default Textarea
