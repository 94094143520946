import getConfig from 'next/config'

export const getEnv = (key) => {
    return getConfig().publicRuntimeConfig[key]
}

export const SURVEY_SERVICE_URL = 'NEXT_PUBLIC_SURVEY_SERVICE_URL'
export const AUTH_URI = 'NEXT_PUBLIC_AUTH_URI'
export const ACCESS_TOKEN_URI = 'NEXT_PUBLIC_ACCESS_TOKEN_URI'
export const ORIGIN = 'NEXT_PUBLIC_ORIGIN'
export const CLIENT_ID = 'NEXT_PUBLIC_CLIENT_ID'
export const LOGOUT_URI = 'NEXT_PUBLIC_LOGOUT_URI'

export const formateDate = (lang, date) => {
    if (!date) {
        return ''
    } else {
        return new Intl.DateTimeFormat(`${lang}-GB`, {
            dateStyle: 'long',
            timeStyle: 'medium'
        }).format(new Date(date))
    }
}

export const dateOnly = (lang, date) => {
    if (!date) {
        return ''
    } else {
        return new Intl.DateTimeFormat(`${lang}-GB`, {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }).format(new Date(date))
    }
}

export const dateInDigits = (lang, date) => {
    if (!date) {
        return ''
    } else {
        return new Intl.DateTimeFormat(`${lang}-GB`, {
            dateStyle: 'medium',
            timeStyle: 'short'
        }).format(new Date(date))
    }
}

export const extractDate = (lang, date) => {
    if (!date) {
        return ''
    } else {
        return new Intl.DateTimeFormat(`${lang}-GB`, {
            dateStyle: 'long'
        }).format(new Date(date))
    }
}

export const remainingDays = (endDate) => {
    return Math.ceil((new Date(endDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))
}

export const secToMins = (date) => {
    return (date / 60).toFixed(1)
}
