import { isPrivate } from '@providers/authentication'
import {
    ADMIN,
    RESEARCHER,
    SUPERVISOR,
    USER,
    STUDIES,
    OPERATION,
    FIELDWORK,
    FIELDWORK_SUPERVISOR,
    AUDITOR
} from '@util/roles'
import { useRouter } from 'next/router'
import { useSelector } from 'noval'

const AuthorizationEnforcer = ({ children }) => {
    const router = useRouter()
    const roles = useSelector('roles')
    const isPolicy = useSelector('userInfo.isPolicyAccepted', true)

    switch (true) {
        case isPrivate(router.asPath) && !isPolicy && hasRole([RESEARCHER], roles):
            router.replace('/terms-and-conditions')
            return null

        case !isPrivate(router.asPath):
        case router.asPath.match(/\/admin(\/.*)?/) && hasRole([ADMIN, RESEARCHER], roles):
        case !router.asPath.match(/\/admin(\/.*)?/) &&
            !router.asPath.match(/\/supervisor(\/.*)?/) &&
            !router.asPath.match(/\/studies(\/.*)?/) &&
            !router.asPath.match(/\/operation(\/.*)?/) &&
            !router.asPath.match(/\/researcher(\/.*)?/) &&
            hasRole([USER], roles):
        case router.asPath.match(/\/supervisor(\/.*)?/) && hasRole([SUPERVISOR], roles):
        case router.asPath.match(/\/researcher(\/.*)?/) && hasRole([RESEARCHER], roles):
        case router.asPath.match(/\/studies(\/.*)?/) && hasRole([STUDIES], roles):
        case router.asPath.match(/\/operation(\/.*)?/) && hasRole([OPERATION], roles):
        case router.asPath.match(/\/auditor(\/.*)?/) && hasRole([AUDITOR], roles):
        case router.asPath.match(/\/fieldwork(\/.*)?/) && hasRole([FIELDWORK], roles):
        case router.asPath.match(/\/fieldwork-supervisor(\/.*)?/) && hasRole([FIELDWORK_SUPERVISOR], roles):
        case router.asPath.match(/\/profile(\/.*)?/) &&
            hasRole([SUPERVISOR, ADMIN, RESEARCHER, STUDIES, OPERATION], roles):
            return children

        case router.asPath.match(/\/[^/]*/) && hasRole([ADMIN], roles):
            router.replace('/admin')
            return null
        case router.asPath.match(/\/[^/]*/) && hasRole([SUPERVISOR], roles):
            router.replace('/supervisor')
            return null
        case router.asPath.match(/\/[^/]*/) && hasRole([RESEARCHER], roles):
            router.replace('/researcher')
            return null
        case router.asPath.match(/\/[^/]*/) && hasRole([STUDIES], roles):
            router.replace('/studies')
            return null
        case router.asPath.match(/\/[^/]*/) && hasRole([OPERATION], roles):
            router.replace('/operation')
            return null
        case router.asPath.match(/\/[^/]*/) && hasRole([AUDITOR], roles):
            router.replace('/auditor')
            return null
        case router.asPath.match(/\/[^/]*/) && hasRole([FIELDWORK], roles):
            router.replace('/fieldwork')
            return null
        case router.asPath.match(/\/[^/]*/) && hasRole([FIELDWORK_SUPERVISOR], roles):
            router.replace('/fieldwork-supervisor')
            return null
        default:
            router.replace('/404')
            return null
    }
}

const hasRole = (role, userRoles) => {
    if (Array.isArray(role)) {
        return role.some((role) => userRoles?.includes(role))
    } else {
        return userRoles?.includes(role)
    }
}

export default AuthorizationEnforcer
