import { hasRole } from '@util/roles'
import { useSelector } from 'noval'

const Authorized = ({ role, children }) => {
    const roles = useSelector('roles')
    const email = useSelector('userInfo.userInfo.email', "")

    if (Array.isArray(role) && role.includes(email)) {
        return children
    } else if (role && hasRole(role, roles)) {
        return children
    } else {
        return null
    }
}

export default Authorized
