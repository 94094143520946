import {
    responsesISISE,
    responses,
    surveyAddon,
    surveyINJ,
    surveyINT,
    orderBase,
    reviewBase,
    auditingBase,
    reviewComment,
    collection,
    userFavorite,
    orderResponsesShared,
    idStatus,
    surveyIN,
    nameFL,
    surveyAnalysis,
    pageInfo,
    targetAudiences
} from 'gql/shared'

const surveyBase = `
    surveyName
    status
    createdDate
    uniqueUrl
    responsesNumber
`

export const shortSurvey = `
    surveys {
        id
        surveyName
        status
        createdDate
        responsesNumber
        latestResponseTime
        surveyType
        uniqueUrl
        surveyScale {
            id
        }
    }
    ${pageInfo}
`

export const surveyList = `
    id
    surveyName
    status
    createdDate
    responsesNumber
    latestResponseTime
    surveyType
    surveyScale {
        id
    }
    targetAudience {
        numberOfResponses
    }
    addons {
        collection
        reporting
        auditing
        review
    }
`

export const surveysTitles = `
        id
        surveyName
`

export const orderSurveyResponses = `
            id
            survey {
               ${surveyINJ}
                responses {
                    ${responsesISISE}
                    responseJson
                    completionTime
                    address {
                        countryCode
                    }
                    location {
                        lat
                        lng
                    }
                    assigment {
                        id
                        targetAudience {
                            id
                            numberOfResponses
                            remainingPriceToAssign
                            male
                            female
                            minAge
                            maxAge
                            minIncome
                            maxIncome
                            maritalStatus
                            education
                            employmentStatus
                            regions
                            cities
                            remainingResponsesToAssign
                        }
                    }
                }
                ${auditingBase}
            }
`
export const sideBarOrder = `
            id
            survey {
                id
                status
                surveyName
                surveyType
                surveyJson
                publishedDate
                createdDate
                lastModifiedDate
                surveyScale {
                    id
                }
            }
`
export const orderSurveyById = `
            survey {
                status
                targetAudience {
                    numberOfResponses
                }
                collect {
                    isCallCenter
                }
                ${surveyINJ}
                surveyType
            }
`
export const orderSurveyResponse = `
        id
        orderName
        status
        selectedAddons
        acceptedResponses
        rejectedResponses
        completedResponses
        survey {
            id
            responsesNumber
            surveyName
            surveyJson
            surveyTheme
            targetAudience {
                numberOfResponses
            }
            audit {
                id
                needRejectedData
            }
            responses {
                id
                responseJson
            }
            collect {
                startDate
                endDate
            }
        }
`

export const surveyResponse = `
    id
    uniqueUrl
    surveyName
    surveyJson
    status
    addons {
        custom
        collect
        audit
    }
    uniqueUrl
    createdDate
    publishedDate
    lastModifiedDate
    previewConfirmation
`

export const allAnswersByQuestion = `
    questionResponses {
        count
        text
    }
    totalResponses
`

export const analysisSurvey = `
    surveyList {
        id
        surveyName
    }
    ${pageInfo}
`

export const aanalyticsDashboard = `
    id
    dashboardName
    lastModifiedDate
`

export const analyticsDashboards = `
    analyticsDashboards {
        id
        survey {
            id
        }
        dashboardName
        lastModifiedDate
    }
    ${pageInfo}
`

export const analyticsDashboardGraph = `
    id
    metric 
    dataPoint
    graphType
    statisticType
    submitDate
`

export const advanceResponseAnalysis = `
    name
    score
    curveScore
    curve
    xx
    yy
`

export const advanceResponseSummary = `
    range
    variance
    standardDev
    min
    max
    mean
    median
    mode
`

export const advanceResponseAnalysisGEO = `
    locations {
        lat
        lng
    }
    GeoDis {
        region
        frequency
    }
`

export const advanceResponseAnalysisWhole = `
    name
    score
    curve
    curveScore
    xx
    yy
`

export const advanceResponseAnalysisWholeTime = `
    distCurve {
        ${advanceResponseAnalysisWhole}
    }
    boxPlot
    summaryAnalysis {
        ${advanceResponseSummary}
    }
`

export const advanceResponseAnalysisDateTime = `
     responsesPerMonth {
     Month
     responses
     }
     responsesPerWeek {
     Day
     responses
     }
     responsesPerDay {
     Day
     responses
     }
     responsesPerHour {
     Hour
     responses
     }
      `

export const ordersGql = `
            ${orderBase}
            orderStage
            ${surveyAnalysis}
            survey {
                ${surveyINJ}
                responsesNumber
                status
                review {
                    ${reviewBase}
                    ${reviewComment}
                }
                responses {
                    ${responsesISISE}
                    ${responses}
                    responseJson
                    completionTime
                    createdDate
                    address {
                        countryCode
                    }
                }
                ${auditingBase}
                ${surveyAddon}
            }
            quote
`

export const ordersQuote = `
    ${orderBase}
    orderStage
    orderName
    selectedAddons
    ${surveyAnalysis}
    survey {
        hasDimension
        ${surveyINJ}
        responsesNumber
        status
        collect {
            callCenter {
                noReply
                rejected
                reschedule
                closed
                noContinue
                noTarget
            }
        }
        review {
            ${reviewBase}
            ${reviewComment}
        }
        ${auditingBase}
        ${surveyAddon}
    }
    quote
`

export const callCenter = `
    count
    status
`

export const callCenterStatistic = `
    dashboard {
        ${callCenter}
    }
    daily {
        ${callCenter}
    }
`

export const callLaterList = `
    id
    responseJson
    callLaterDate
    status
`

export const draftResponses = `
    id
    responseJson
`

export const orderSurveyId = `
            ${idStatus}
            survey {
                ${surveyINJ}
                responses {
                    id
                    responseJson
                    ${responses}
                }
            }
`
export const surveyReviewLink = `
    ${surveyINJ}
`
export const assignmentsByTypeOnResearcher = `
            id
            orderName
            survey {
                ${surveyINJ}
                numberOfViews
            }
 
`
export const surveyReviewComment = `
        ${surveyIN}
        review {
            ${reviewComment}
        }
`
export const orderSurveyReviewComment = `
    ${surveyReviewComment}
`

export const anonymousCommentReview = `
        id
        questionName
        questionTitle
        questionComment
        checkSolved
`

export const customerDashboard = `
    customerStatics {
        allSurveys
        draftSruveys
        publishedSruveys
        closedSruveys
    }
`

export const dashboardQuery = `
           ${orderBase}
            survey {
                ${surveyINT}
                responsesNumber
                status
                responses {
                    ${orderBase}
                    completionTime
                }
            }
            status
`

export const operationsQuery = `
            id
            createdDate
            status
            survey {
                ${surveyBase}
                responses {
                    ${responsesISISE}
                    ${responses}
                    responseJson
                    completionTime
                    createdDate
                    address {
                        countryCode
                    }
                }
                ${auditingBase}
            }
`
export const ordersQuery = `
            id
            createdDate
            survey {
                ${surveyBase}
                responses {
                    ${orderBase}
                }
                ${auditingBase}
            }
`
export const publishSurveyTemplates = `
            ${idStatus}
            price
            description
            duration
            surveyJson
            surveyName
            surveyTheme
            surveyLogo
`
export const orders = `
            ${orderBase}
            orderStage
            survey {
                ${surveyINT}
                responsesNumber
                status
                latestResponseTime
                addons {
                    collection
                    reporting
                    auditing
                    review
                }
                targetAudience {
                    numberOfResponses
                }
                surveyScale {
                    id
                }
            }
`

export const surveyCreatorGql = `
    ${surveyINJ}
    status
    isAllQuestionsRequired
    surveyType
`

export const surveyGql = `
    ${surveyINJ}
    status
    responsesNumber
    isAllQuestionsRequired
    review {
        ${reviewBase}
        ${reviewComment}
    }
    ${auditingBase}
    surveyType
    ${surveyAddon}
    ${collection}
    ${targetAudiences}
    previewConfirmation
`

export const surveyDesignGql = `
    ${surveyINJ}
    status
    responsesNumber
    surveyType
    previewConfirmation
`

export const surveyAuditing = `
    ${surveyINT}
    surveyJson
    status
    responsesNumber
    review {
        ${reviewBase}
    }
    ${auditingBase}
    ${surveyAddon}
    ${collection}
`

export const surveyCollection = `
    ${collection}
`
// surveyOverview(id: $id)
export const userSurveyOverview = `
    numberOfViews
    responsesNumber
    completedResponses
    dailyNotCompletedResponses
    answerAvgTime
    completed
    drop
    location {
        lat
        lng
    }
    completionPercentage
    responsesPerDay {
        Day
        responses
    }
    responsesPerHour {
        Hour
        responses
    }
    responsesOverview {
        source
        visits
        visitAvgTime
        visitTotalTime
        completed
        drop
        responseFullDate
    }
`
export const ordersHasAddonsSurvey = `
            ${orderBase}
            quote
            orderStage
            survey {
                surveyName
                status
                lastModifiedDate
                ${surveyAddon}
            }
`

export const userPageableOrdersHasAddons = `
    orders {
        ${orderBase}
        quote
        orderName
        orderStage
        selectedAddons
        survey {
            surveyName
            status
            lastModifiedDate
            addons {
                collect
                audit
            }
        }
    }
    ${pageInfo}
`

export const userBaseData = `
    userInfo {
        email
        fullName
        mobileNumber
        keycloakUserId
        personalPicture
    }
`
export const userData = `
    notification
    ${userBaseData}
    ${userFavorite}
    isPolicyAccepted
    nationalId
    cvFile
    status
    bank
    iban
`

export const publicFaqs = `
    id
    question
    answer
    language
`

export const getOpenSurvey = `
    surveyName
    surveyJson
    surveyTheme
`

export const surveyLaunch = `
    ${surveyINJ}
    ${getOpenSurvey}
`

export const orderDetailsTitle = `
    id
    survey {
        ${surveyINJ}
        status
        responses {
            respondent {
               ${nameFL}
            }
            ${orderResponsesShared}
            createdDate
        }
        numberOfViews
    }
`

export const analyzeResponsesBySurveyId = `
    id
    totalPages
    responses {
        responseJson
    }
`

export const allResponsesDownload = `
    responses {
        id
        responseJson
    }
`

export const analyzeResponses = `
    id
    survey {
        id
        surveyName
        surveyJson
        totalPages
        pageNo
        responses {
            id
            responseJson
            startDate
            endDate
            completionTime
        }
    }

`
export const exportResponses = `
    id
    surveyName
    surveyJson
    responses {
        id
        collectorName
        responseJson
        responseSource
        status
        startDate
        endDate
        completionTime
        location{
            lat
            lng
        }
        isCompleted
        isModified
        auditorName
        auditStatus
    }
`

export const getSurveyScale = `
    id
    scaleName
    scaleJson
    scaleTheme
    scaleLogo
    description
    shortDescription
    duration
    status 
    image
    targetAudience
    scaleOutcome
`

export const encodingGroup = `
    id
    questions
    choices
`

export const generateScaleResponsePDF = `
    isNameRequired
    respondentName
    scaleName
    result {
        dimensionName
        score
        singleExplain
        groupExplain
    }
    finalExpression
    finalScore
    maxValue
`

export const generateScaleResponseChart = `
    name
    data
`

export const generateScaleResponseChartIndi = `
    dimensions {
        name
        score
        dimensionName
        singleExplain
        groupExplain
        recommendation
    }
    finalDegree {
        name
        score
        dimensionName
        singleExplain
        groupExplain
        recommendation
    }
    maxValue
`

export const generateScaleResponseChartAll = `
    dimensions {
        id
        dimensionName
        responseChart {
            dimensionId
            name
            score
            dimensionName
            singleExplain
            groupExplain
            recommendation
        }
    }
    finalDegree {
        dimensionId
        name
        score
        dimensionName
        singleExplain
        groupExplain
        recommendation
    }
`
export const analyzeSurveyQuestions = `
    semanticDiversity
    alignQuestionsWithGoals
    alignQuestionsWithChoices {
        question
        choices
    }
    ensureBiasQuestions
`