import React from 'react'

const CheckBoxHolder = ({ checked, children, label, error, inputName, parentCss = '' }) => {
    return (
        <label
            className={`flex items-center cursor-pointer select-none ${parentCss}`}
            htmlFor={inputName}
        >
            <span>{label}</span>

            <div
                name={inputName}
                className='relative'
            >
                {children}
                <div
                    className={`block  w-16 h-8 rounded-full ${
                        checked ? 'bg-primary-600' : 'bg-white border border-light-500'
                    } `}
                ></div>
                <div
                    className={`dot absolute ${
                        checked ? 'right-1 bg-white' : 'left-1 bg-light-500'
                    } top-1  w-6 h-6 rounded-full transition `}
                ></div>
            </div>

            <p className='text-xs font-light text-[#f87171] lowercase ms-1'>{error}</p>
        </label>
    )
}

export default CheckBoxHolder
