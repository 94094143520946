import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import * as propTypes from 'prop-types'
import { Field, getIn } from 'formik'
import InputHolder from '@components/InputHolder'
import CheckBoxHolder from './checkBoxHolder'
import Tag, { buildClass } from '@util/tag'

const Input = ({
    name,
    label,
    _required,
    error = '',
    customclass,
    customwidth,
    type = 'text',
    form = true,
    parentCss,
    customIcon,
    customLabel,
    checked,
    stopErrorMessage = false,
    ref,
    labelClass,
    inputHolderStyle,
    ...props
}) => {
    const { lang } = useTranslation()

    const inputStyle =
        'py-1 px-5 border border-[#e6e7e9] text-black h-11 focus:ring-0 disabled:bg-gray-200 disabled:opacity-50'

    const holderProps = {
        label: label,
        inputName: name,
        parentCss: parentCss,
        labelClass: labelClass,
        inputHolderStyle: inputHolderStyle
    }
    const inputProps = {
        id: name,
        name: name,
        type: type
    }

    if (form) {
        return (
            <Field name={name}>
                {({ field, form: { errors, touched } }) => {
                    if (type === 'radio') {
                        return (
                            <div className={buildClass('flex gap-3 items-center', parentCss)}>
                                <input
                                    {...inputProps}
                                    {...field}
                                    className={buildClass(
                                        'form-check-input appearance-none',
                                        'border border-primary-600 bg-white checked:bg-primary-600',
                                        'transition duration-200 bg-no-repeat cursor-pointer',
                                        'checked:border-primary-600 focus:outline-none',
                                        customclass
                                    )}
                                    checked={checked}
                                    {...props}
                                />
                                <label
                                    className={buildClass('form-check-label inline-block text-gray-800', labelClass)}
                                >
                                    {label}
                                </label>
                            </div>
                        )
                    } else if (type === 'checkbox') {
                        return (
                            <CheckBoxHolder
                                {...holderProps}
                                checked={checked}
                                error={getIn(touched, field.name) && getIn(errors, field.name)}
                            >
                                <input
                                    id={name}
                                    name={name}
                                    onClick={field.onClick}
                                    value={field.value}
                                    type='checkbox'
                                    className='sr-only'
                                    {...props}
                                />
                            </CheckBoxHolder>
                        )
                    } else if (type === 'phone') {
                        return (
                            <InputHolder
                                {...holderProps}
                                error={getIn(touched, field.name) && getIn(errors, field.name)}
                            >
                                {customIcon && (
                                    <div
                                        className={`absolute flex h-full items-center px-4 ${
                                            lang === 'ar' ? 'right-1' : 'left-1'
                                        }`}
                                    >
                                        {customIcon}
                                    </div>
                                )}
                                {customLabel && <div>{customLabel}</div>}
                                <input
                                    {...inputProps}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    value={field.value}
                                    className={buildClass(inputStyle, 'w-64 pt-[0.37rem]', customclass, customwidth, {
                                        'ps-14': customIcon
                                    })}
                                    {...props}
                                />

                                <input
                                    type='text'
                                    placeholder='+966'
                                    dir='ltr'
                                    className={buildClass(
                                        inputStyle,
                                        'input-primary border-l border-[black] rounded-r'
                                    )}
                                    readOnly
                                    style={{ width: '5rem' }}
                                />
                            </InputHolder>
                        )
                    } else {
                        return (
                            <InputHolder
                                {...holderProps}
                                error={stopErrorMessage ? '' : getIn(touched, field.name) && getIn(errors, field.name)}
                                parentCss={parentCss}
                            >
                                {customIcon && (
                                    <div
                                        className={`absolute flex h-full items-center px-4 ${
                                            lang === 'ar' ? 'right-1' : 'left-1'
                                        }`}
                                    >
                                        {customIcon}
                                    </div>
                                )}
                                {customLabel && <div>{customLabel}</div>}
                                <input
                                    {...inputProps}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    value={field.value}
                                    className={buildClass(inputStyle, customclass, customwidth, {
                                        'ps-14': customIcon
                                    })}
                                    {...props}
                                />
                            </InputHolder>
                        )
                    }
                }}
            </Field>
        )
    } else {
        return (
            <InputHolder
                {...holderProps}
                error={error}
            >
                {customIcon && (
                    <Tag className={['absolute flex h-full items-center px-4', { 'right-3': lang === 'en' }]}>
                        {customIcon}
                    </Tag>
                )}
                <input
                    {...inputProps}
                    className={buildClass(
                        inputStyle,
                        'bg-[#fcfcfd]',
                        customclass,
                        !customwidth ? 'w-64' : customwidth,
                        { 'ps-14': customIcon }
                    )}
                    ref={ref}
                    {...props}
                />
            </InputHolder>
        )
    }
}

Input.propTypes = {
    name: propTypes.string,
    label: propTypes.oneOfType([propTypes.object, propTypes.string]),
    required: propTypes.bool,
    error: propTypes.string
}

export default Input
