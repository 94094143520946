import Header from '@components/home/Header'
import Solutions from '@components/home/Solutions'
import ContactMessage from '@components/home/contact-message'
import RenderMeta from '@util/RenderMeta'
import { buildClass } from '@util/tag'
import useTranslation from 'next-translate/useTranslation'
import React, { memo } from 'react'

const IndexPage = () => {
    const { lang } = useTranslation()

    return (
        <div className='relative'>
            <RenderMeta />
            <Header />
            <Solutions />

            <div
                className={buildClass(
                    'sm:hidden visible w-12 lg:w-16 sticky bottom-4 z-20',
                    lang === 'ar' ? 'right-[90%]' : 'left-[90%]'
                )}
            >
                <ContactMessage />
            </div>
        </div>
    )
}

export default memo(IndexPage)
