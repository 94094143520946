import { buildClass } from '@util/tag'
import Trans from 'next-translate/Trans'
import React from 'react'

const InputHolder = ({
  children,
  label,
  error,
  inputName,
  inputHolderStyle,
  parentCss = '',
  errorStyle,
  labelClass = 'text-md'
}) => {
  return (
    <div
      className={buildClass(
        'flex flex-col text-dark-400 text-md gap-1 justify-between',
        parentCss
      )}
    >
      {label && (
        <label
          htmlFor={inputName}
          className={buildClass('capitalize', labelClass)}
        >
          {label}
        </label>
      )}

      <div
        name={inputName}
        className={`relative ${inputHolderStyle}`}
      >
        {children}
      </div>

      {error && (
        <p
          className={buildClass(
            'text-md font-light text-primary-600 lowercase ms-1',
            errorStyle
          )}
          // dangerouslySetInnerHTML={{
          //     __html: <Trans i18nKey={error?.props?.i18nKey} />
          // }}
        >
          {error?.props?.i18nKey ? (
            <Trans i18nKey={error?.props?.i18nKey} />
          ) : (
            error
          )}
        </p>
      )}
    </div>
  )
}

export default InputHolder
